<template>
  <div class="container">
    <carouselBanner paddingTop="14%" :bannerType="10" />
    <div class="content_area" ref="list">
      <div class="clearfix">
        <div v-loading="loading">
          <div v-if="currentData">
            <h2 class="title">{{ currentData.title }}</h2>
            <div class="date">
              发布时间： {{ currentData.created_time | secondFormat("LL") }}
            </div>
            <div style="min-height: 300px" class="main">
              <template v-if="currentData.sub_type == 1">
                <el-image
                  v-for="(item, index) in currentData.content"
                  :key="index"
                  :src="item"
                  :preview-src-list="currentData.content"
                  fit="contain"
                ></el-image>
              </template>
              <template v-else-if="currentData.sub_type == 3">
                <iframe
                  width="100%"
                  height="800px"
                  :src="
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                    currentData.content[0]
                  "
                  frameborder="0"
                ></iframe>
              </template>
              <template v-else-if="currentData.sub_type == 2">
                <iframe
                  :src="currentData.content[0]"
                  frameborder="0"
                  style="width: 100%; height: 800px"
                ></iframe>
              </template>
            </div>
            <!-- <div class="nodataBox">
              <noDataImg />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carouselBanner from "@/baseComponents/carouselBanner";
export default {
  metaInfo: {
    title: "信息公示详情",
  },
  components: {
    carouselBanner,
  },
  data() {
    return {
      currentData:{},
      detailID: "",
      loading: false,
    };
  },
  mounted() {
    this.detailID = this._decode(this.$route.query.parameter).detailID;
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.loading = true;
      let params = {
        id: this.detailID,
        is_admin: 0,
      };
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      this.loading = false;
      if (res && res.success) {
        this.currentData = res.data;
        if (this.currentData.content && this.isJSON(this.currentData.content)) {
          this.currentData.content = JSON.parse(this.currentData.content);
        }
        console.log(this.currentData);
      }
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.log("error：" + str + "!!!" + e);
          return false;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.container {
  padding-bottom: 24px;
  background: #f2f4f6;
  .content_area {
    .title {
      font-size: 21px;
      width: 100%;
      word-break: break-all;
      font-weight: 900;
      line-height: 30px;
      padding: 10px;
      text-align: center;
    }
    .date{
      text-align: center;
      color: #909399;
      padding-bottom: 20px;
    }
  }
  .nodataBox {
    height: 400px;
    width: 200px;
    margin: auto;
    position: relative;
    text-align: center;
  }
  .main {
    display: flex;
    min-height: 300px;
    flex-wrap: wrap;
    justify-content: center;
    .el-image {
      width: 100%;
    }
  }
}
</style>